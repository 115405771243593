import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { ClientEntity } from '@core/entities/client/client.entity';
import {
  OauthAuthorizeRedirectQueryParamsEntity,
} from '@core/entities/query-params/oauth-authorize-redirect-query-params.entity';
import { ClientService } from '@core/services/client/client.service';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { deserialize } from 'serializr';
import { ClientType } from '@core/models/oidc/client/client-type';

export const oauthClientInfoResolver = (): ResolveFn<ClientEntity> => {
  return (activatedRouteSnapshot: ActivatedRouteSnapshot) => {
    const clientService = inject(ClientService);

    const oauthAuthorizeRedirectRequest = deserialize(
      OauthAuthorizeRedirectQueryParamsEntity,
      activatedRouteSnapshot.queryParams
    );

    if (!oauthAuthorizeRedirectRequest.clientId) {
      return of(new ClientEntity());
    }

    return clientService.getClientInfo(oauthAuthorizeRedirectRequest.clientId, ClientType.OAUTH).pipe(
      map((client: ClientEntity) => {
        return client;
      }),
      catchError(() => {
        return of(new ClientEntity());
      })
    );
  };
};
