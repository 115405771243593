<header>
  <app-public-header />
</header>

<main>
  <router-outlet />
</main>

<footer>
  <a routerLink="/about" target="_blank">Cookies</a>
  <span class="dot">&nbsp;⋅&nbsp;</span>
  <a href="https://www.fagforbundet.no/personvern/" target="_blank">Personvernerklæring</a>
  @if (notOnDocumentationPage) {
    <span>
      <span class="dot">&nbsp;⋅&nbsp;</span>
      <a routerLink="/documentation" target="_blank">Utviklerdokumentasjon</a>
    </span>
  }
</footer>
