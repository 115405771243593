import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { ClientEntity } from '@core/entities/client/client.entity';
import { SsoDataQueryParamsEntity } from '@core/entities/query-params/sso-data-query-params.entity';
import { ClientTypesEnum } from '@core/enums/client-types.enum';
import { ClientService } from '@core/services/client/client.service';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { deserialize } from 'serializr';
import { ClientType } from '@core/models/oidc/client/client-type';

export const ssoClientInfoResolver = (): ResolveFn<ClientEntity> => {
  return (activatedRouteSnapshot: ActivatedRouteSnapshot) => {
    const clientService = inject(ClientService);

    const ssoDataParams = deserialize(SsoDataQueryParamsEntity, activatedRouteSnapshot.queryParams);

    if (ssoDataParams.appId) {
      return clientService.getClientInfo(ssoDataParams.appId, ClientType.SSO).pipe(
        map((client: ClientEntity) => {
          return client;
        }),
        catchError(() => {
          return of(new ClientEntity());
        })
      );
    } else {
      return clientService.getInfoByRedirectUri(ssoDataParams.returnTo, ClientTypesEnum.SSO).pipe(
        map((client: ClientEntity) => {
          return client;
        }),
        catchError(() => {
          return of(new ClientEntity());
        })
      );
    }
  };
};
