import { AfterViewInit, Component, ComponentRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ClientEntity } from '@core/entities/client/client.entity';
import { PendingInformationUpdates } from '@core/entities/login/pending-information-updates.entity';
import {
  OauthAuthorizeRedirectQueryParamsEntity,
} from '@core/entities/query-params/oauth-authorize-redirect-query-params.entity';
import { OldUserEntity } from '@core/entities/user/user.entity';
import { UserService } from '@core/services/user/user.service';
import { LoginFormsComponent } from '@shared/components/login-forms/login-forms.component';
import { SetUserPasswordComponent } from '@shared/components/set-user-password/set-user-password.component';
import { take, tap } from 'rxjs/operators';
import { deserialize } from 'serializr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements AfterViewInit {
  @ViewChild('viewContainer', { read: ViewContainerRef })
  viewContainerRef: ViewContainerRef;

  clientInfo: ClientEntity = new ClientEntity('ID');

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
  ) {}

  /**
   * Dynamically load the LoginsForms component which will be replaced by the
   * SetUserPasswordForm component if PendingInfoUpdates say password is required
   */
  ngAfterViewInit(): void {
    this._activatedRoute.queryParams
      .pipe(
        tap((params: Params) => {
          const oauthAuthorizeRedirect = deserialize(OauthAuthorizeRedirectQueryParamsEntity, params);

          if (oauthAuthorizeRedirect.isOidcCallback) {
            this._loginCallback();
          } else {
            const component = this.#loadComponent<LoginFormsComponent>(LoginFormsComponent);

            component.instance.clientInfo = this.clientInfo;

            component.instance.loggedInCallback.subscribe(() => {
              this._loginCallback();
            });
          }
        }),
      )
      .subscribe();
  }

  private _checkPendingInfoUpdates(): void {
    this._userService
      .getPendingInformationUpdates()
      .pipe(
        tap((pendingInformationUpdates: PendingInformationUpdates) => {
          if (pendingInformationUpdates.password.required) {
            this._showUserPasswordForm(pendingInformationUpdates);
          } else {
            this._navigateAfterLogin();
          }
        }),
        take(1),
      )
      .subscribe();
  }

  #loadComponent<T>(comp: any, clear: boolean = true): ComponentRef<T> {
    if (clear) {
      this.viewContainerRef.clear();
    }

    return this.viewContainerRef.createComponent<T>(comp);
  }

  private _loginCallback(): void {
    this._checkPendingInfoUpdates();
  }

  private _navigateAfterLogin() {
    this._router.navigate(['user/profile']).catch();
  }

  private _showUserPasswordForm(pendingInformationUpdates: PendingInformationUpdates): void {
    this._userService
      .getOldSelf()
      .pipe(
        tap((user: OldUserEntity) => {
          const component = this.#loadComponent<SetUserPasswordComponent>(SetUserPasswordComponent);
          component.instance.user = user;
          component.instance.pendingInfoUpdates = pendingInformationUpdates;
          component.instance.proceedCallback.subscribe(() => {
            this._navigateAfterLogin();
          });
        }),
        take(1),
      )
      .subscribe();
  }
}
