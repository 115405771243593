import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { OldUserEntity } from '@core/entities/user/user.entity';
import { SessionService } from '@core/services/user/session.service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard implements CanActivate {
  constructor(private readonly _sessionService: SessionService, private readonly _router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this._sessionService.instantiateUser().pipe(
      map((isLoggedIn: OldUserEntity | null) => {
        if (isLoggedIn) {
          return this._router.createUrlTree(['user/profile']);
        } else {
          return true;
        }
      }),
      take(1)
    );
  }
}
